@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$purple: #ae3d97;
$teal: #31aec2;
$light-purple: #c065ac;
$black: #131516;

$very-light-gray: #efefef;
$light-gray: #8c979a;
$medium-gray: #4d4d4d;
$dark-gray: #1b1c1d;

$divider-color: #d7d8d8;

$divider: 1px solid $divider-color;

$link-color: $light-gray;
$link-hover-color: rgba(140, 151, 154, 0.6);
$body-color: $medium-gray;

$selectable-height: 46px;

$transition: 0.1s background-color linear, 0.1s color linear, 0.1s fill linear;

$calendar-divider: $divider;

$cart-divider-color: $divider-color;

$members-menu-icon-color: $primary-color;

$datepicker-icon-color: $black;
$datepicker-icon-color-hover: white;
$datepicker-icon-color-active: white;

$radio-button-border-color: $light-gray;
$radio-button-selected-color: $light-gray;
$radio-button-checked-border-color: $light-gray;

$select-arrow-color: $black;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%h1 {
  text-transform: uppercase;

  @include small {
    font-size: 32px;
  }
}

%h2 {
  font-size: 32px;

  @include small {
    font-size: 28px;
  }
}

%button {
  transition: $transition;
  text-transform: uppercase;
  letter-spacing: 1.29px;
  font-family: acumin-pro-condensed, Arial;
}

%primary-button {
  color: white;
  background-color: $primary-color;
  padding: 16px 37px;
}

%primary-button-hover {
  background-color: $light-purple;
}

%secondary-button {
  color: $secondary-color;
  border: 2px solid $secondary-color;
  padding: 14px 35px;
  background-color: transparent;
}

%secondary-button-hover,
%secondary-button-active {
  color: white;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

%back-button {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 14px;
  transition: $transition;
}

%input {
  border: 1px solid #ccc;
  background: #fafafa;
  border-radius: 2px;
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-2';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';
