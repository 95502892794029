.body-inner .site-content-inner {
  padding-top: 24px;
}

a {
  transition: color 0.15s ease-out;
}

article.event-listing-item.event {
  h2 {
    margin-bottom: 8px;
  }

  .subtitle .value {
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $light-gray;
    margin-bottom: 16px;
  }

  div.button.primary {
    background-color: transparent;
    color: $black;
    padding: 0;
    font-family: $primary-font;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: bold;
    background: url("data:image/svg+xml,%3Csvg width='18' height='6' viewBox='0 0 18 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7854 1.16144e-06C14.5835 0.807108 15.2732 1.41929 15.8545 1.83959C16.4359 2.2599 16.9843 2.57361 17.5 2.77767L17.5 3.15229C16.9055 3.42031 16.3308 3.76142 15.7724 4.17564C15.2141 4.58985 14.5473 5.19899 13.7755 6L13.0825 6C13.6474 4.88223 14.2386 4.02335 14.8561 3.42031L0.5 3.4203L0.5 2.57969L14.8561 2.5797C14.3995 2.04061 14.081 1.63858 13.9036 1.37665C13.723 1.11472 13.4569 0.654823 13.1022 1.10172e-06L13.7854 1.16144e-06Z' fill='black'/%3E%3C/svg%3E")
      no-repeat center right;
    padding-right: 26px;

    &:hover {
      background-color: transparent;
      color: $medium-gray;
    }
  }
}

.event-listing-with-filters {
  > h1 {
    margin-bottom: 16px;
  }
}

.stepper {
  button {
    > svg {
      transition: $transition;
    }

    &:hover {
      > svg {
        fill: $purple;
      }
    }
  }
}

.selection {
  .select-tickets {
    margin-bottom: 0;
  }

  .ticket-type {
    border-top: $divider;
    padding: 16px 0;
    margin-bottom: 0;
  }
}

.section-header h3 {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.5em;
  text-transform: uppercase;
  color: $medium-gray;
  font-weight: bold;
}

.content-detail-page {
  .radio-group.ticket-types {
    .ticket-type {
      margin-bottom: 28px;

      .custom-value {
        .currency-symbol {
          font-weight: bold;
          color: $black;
          font-size: 18px;
        }
      }
    }
  }

  &.donation {
    .ticket-groups h2 {
      margin-bottom: 8px;
    }

    .description {
      margin-bottom: 24px;

      p {
        margin: 0;
      }
    }
  }
}

.selection button.primary {
  color: white;
}

.select-tickets {
  .ticket-type.fixed-price {
    .price-wrapper {
      color: rgba(19, 21, 22, 0.7);
      font-weight: normal;
    }
  }
}

section.cart {
  border: 1px solid $cart-divider-color;

  > h2 {
    background-color: $dark-gray;
    color: white;
    padding: 20px;
    text-transform: none;
    font-size: 28px;
  }

  button.primary.purchase {
    font-size: 22px;
    padding: 20px;
  }

  .content {
    .add-promo-button {
      padding: 20px;
      font-size: 16px;
    }

    .ticket-date {
      color: $body-color;
      font-weight: normal;
      font-size: 14px;
    }

    .total-prices {
      background-color: transparent;
      padding: 20px 20px 28px;
    }

    .add-promo-form,
    .ticket-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    .ticket-wrapper .actions button.remove {
      color: $black;
    }
  }

  .ticket {
    header {
      h4,
      .price-wrapper {
        font-size: 20px;
      }
    }

    .ticket-date {
      margin-top: 8px;
    }
  }
}

.modal {
  section.cart {
    .ticket-wrapper,
    .total-prices {
      padding-left: 16px;
      padding-right: 16px;
    }

    .add-promo-form {
      padding-left: 0;
      padding-right: 0;
    }

    .add-promo-button {
      color: $primary-color;
    }
  }
}

.radio-group {
  label.radio-item:not(.selectable) {
    .label {
      font-weight: bold;
      font-size: 18px;
      color: $black;
    }

    input[type='radio'] {
      margin-right: 10px;
    }
  }
}

.filters {
  margin-bottom: 48px;
}

.complete {
  @include small {
    picture.background img {
      height: 140px;
    }
  }

  .site-content-inner {
    padding-top: 0;
  }

  .main {
    h1 {
      text-transform: none;

      span {
        text-transform: uppercase;
        margin-bottom: 8px;
      }
    }
  }
}

.modal {
  h1 {
    text-transform: none;
    margin-bottom: 12px;
  }

  &.membership-modal {
    .content .label {
      font-weight: bold;
    }
  }
}

.checkout-route {
  .apply-gift-cards {
    .add-card-button {
      color: $purple;
      font-size: 16px;

      &:before {
        margin-right: 3px;
      }
    }

    button.apply-card {
      padding: 16px 0;
      color: $black;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 14px;

      &:hover {
        color: $purple;
      }
    }
  }
}

.selectable-button,
.selected-date-time {
  text-transform: uppercase;
  letter-spacing: 1.29px;
  font-family: acumin-pro-condensed, Arial;
}

.select-available-session,
.date-selector {
  .selectable-button {
    border: 2px solid $secondary-color;
    transition: $transition;

    &.available {
      @include hover {
        color: white;
        background-color: $secondary-color;
        border-color: $secondary-color;

        .availability-status:not(.danger) {
          color: white;
        }
      }
    }
  }
}

.selected-date-time {
  outline-color: black;
}
