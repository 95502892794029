.site-header {
  .site-header-inner {
    padding: 20px 0;
  }

  .logo {
    width: 140px;
  }

  .account-links {
    a {
      font-weight: 700;
      font-size: 17px;
      color: $black;

      &:hover {
        color: $light-gray;
      }
    }
  }
}
