.site-footer {
  background-color: $dark-gray;
  padding: 20px 0;
  text-align: center;

  p {
    color: #8c979a;
    margin: 0;
  }
}
